import React from "react";
import { navigate } from "gatsby";
import Cookies from "universal-cookie";

import Layout from "../../components/layoutEs";
import Seo from "../../components/seoEs";

import Agegate from "../../components/agegateEs";

const AgeGate = () => {
  let ageCookie = new Cookies();

  if (ageCookie.get("isOldEnough")) {
    navigate("/es");
  }

  return (
    <Layout>
      <Seo
        title="Portal de edad | Seguros Snickers"
        lang="es"
      />

      <Agegate />
    </Layout>
  );
};

export default React.memo(AgeGate);

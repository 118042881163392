import React, { useState, useEffect } from "react";
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

import AgeCalculator from "./agegate-component-es";
import Footer from "./footer/footerEs";
import LanguageSwitcher from "./header/languageSwitcherEs";

import snickersLogo from "../images/snickers-hunger-insurance-es.png";
import oxxo from "../images/es/logo-oxxo-mr.png";

const AgeGate = (props) => {
  const [isOldError, setIsOldError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  useEffect(() => {
    document.querySelector("body").classList.add("agegate");
  }, []);
  return (
    <>
      <section className="agegate-hldr pt d-flex justify-content-between flex-column align-items-center pt-5">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <LanguageSwitcher />
          <Link to="/es">
            <figure className="logo">
              {/* <StaticImage
                src="../images/snickers-hunger-insurance-es.png"
                alt="Snickers Logo"
                quality={100}
              /> */}
              <img
                src={snickersLogo}
                alt="Snickers Logo"
                className="img-fluid"
              />
            </figure>
          </Link>
          {/* <h1 className="text-white text-uppercase">
            Informe sua idade para ter o seguro
          </h1> */}
          <h1 className="text-white text-uppercase">
            Para asegurarte, introduce tu edad
          </h1>
          <AgeCalculator
            setIsOldError={setIsOldError}
            setAgeError={setAgeError}
            setAgeGateShow={props.setAgeGateShow}
          />
          {isOldError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Ups, por favor completa tus datos y presiona enter.
            </p>
          )}
          {ageError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Lo sentimos, no estás autorizado a entrar a este sitio.
            </p>
          )}
          <div className="d-flex align-items-end">
            <h4 className="text-white">EXCLUSIVO&nbsp;EN:</h4>
            <img className="oxxo-logo" src={oxxo} alt="OXXO Logo" />
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AgeGate;
